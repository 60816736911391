import React, { Component } from "react";
import Table from "react-bootstrap/Table";

import { useAuth0 } from "@auth0/auth0-react";

const UserDashboardComponent = (props) => {
  const { getAccessTokenSilently } = useAuth0();

  const renderLoginTreble = (user) => {
    let companyName = props.company.name.replaceAll(" ", "_").toLowerCase();
    if (user.first_name === "treble" && user.last_name === companyName) {
      return (
        <button
          className="external-login-container"
          onClick={async () => {
            const token = await getAccessTokenSilently();
            props.adminLogin(token, user.id);
          }}
        >
          <p>Login</p>
          <div className="icon icon--external-link" />
        </button>
      );
    }
    return;
  };

  return (
    <>
      <h4 className="header">This company has the following users</h4>
      <div class="Agent">
        <div class="agent" align="left">
          <Table responsive bordered striped>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Login</th>
              </tr>
            </thead>
            <tbody>
              {props.company.users.map((user) => (
                <tr key={`User_${user.id}`}>
                  <td>{user.first_name + " " + user.last_name}</td>
                  <td>{user.email}</td>
                  <td>{renderLoginTreble(user)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
};

export default UserDashboardComponent;
