import React, { Component } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Jumbotron, Badge, Alert } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import UserAgentRecord from "./UserAgentRecord";

import "./mainDashboardComponent.scss";
// assets


class MainDashboardComponent extends Component {
  USER = "USER";
  AGENTS = "AGENTS";
  AGENT = "AGENT";
  ADMIN = "ADMIN";
  SUPERVISOR = "SUPERVISOR";

  constructor(props) {
    super(props);
    this.state = {
      type: this.USER,
      subtype: "",
      addTag: false,
      addTeam: false,
      newTag: "",
      selectedTeamId: 0,
      newTeam: "",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      checkedTags: [],
      checkedTeams: []
    };
  }

  handleSelectType = type => {
    if (type === this.USER) {
      this.setState({ type, subtype: "" });
    } else {
      this.setState({ type, subtype: this.AGENT });
    }
  };

  handleSelectSubtype = subtype => {
    this.setState({ subtype });
  };

  setAgentSubtype = type => {
    if (this.state.type === type) {
      return (
        <Dropdown>
          <Dropdown.Toggle variant="outline-primary mb-2" id="dropdown-subtype">
            Select agent subtype
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={() => this.handleSelectSubtype(this.ADMIN)}>
              Admin
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => this.handleSelectSubtype(this.SUPERVISOR)}
            >
              Supervisor
            </Dropdown.Item>
            <Dropdown.Item onClick={() => this.handleSelectSubtype(this.AGENT)}>
              Agent
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      );
    } else {
      return null;
    }
  };

  renderTagTeams = (type, subtype) => {
    if (this.state.type === type && subtype === this.SUPERVISOR) {
      return (
        <div align="left">
          <div>
            <b>Tags</b>
          </div>
          <ul className="tag-teams">
            {this.props.company.tags.map(tag => (
              <li key={`tag-${tag.name}`} className="mb-1">
                <Form.Check
                  name={tag.id}
                  id={`tag-${tag.name}`}
                  label={tag.name}
                  onChange={e => {
                    this.handleCheckboxChangeTags(e);
                  }}
                />
              </li>
            ))}
          </ul>
          <div>
            <b>Teams</b>
          </div>
          <ul className="tag-teams">
            {this.props.company.teams.map(team => (
              <li key={`team-${team.name}`} className="mb-1">
                <Form.Check
                  name={team.id}
                  id={`team-${team.name}`}
                  label={team.name}
                  onChange={e => {
                    this.handleCheckboxChangeTeams(e);
                  }}
                />
              </li>
            ))}
          </ul>
        </div>
      );
    } else if (this.state.type === type) {
      return (
        <div align="left">
          <div>
            <b>Tags</b>
          </div>
          <ul className="tag-teams">
            {this.props.company.tags.map(tag => (
              <li key={`tag-${tag.name}`} className="mb-1">
                <Form.Check
                  name={tag.id}
                  id={`tag-${tag.name}`}
                  label={tag.name}
                  onChange={e => {
                    this.handleCheckboxChangeTags(e);
                  }}
                />
              </li>
            ))}
          </ul>
        </div>
      );
    }
  };

  handleCheckboxChangeTeams = event => {
    const target = event.target;
    const checked = target.checked;
    const name = target.name;
    const alreadyCheckedTeams = this.state.checkedTeams;
    if (checked) {
      alreadyCheckedTeams.push(name);
      this.setState({
        checkedTeams: alreadyCheckedTeams
      });
    } else {
      const index = alreadyCheckedTeams.indexOf(name);
      alreadyCheckedTeams.slice(index, 1);
      this.setState({
        checkedTeams: alreadyCheckedTeams
      });
    }
  };

  handleCheckboxChangeTags = event => {
    const target = event.target;
    const checked = target.checked;
    const name = target.name;
    const alreadyCheckedTags = this.state.checkedTags;
    if (checked) {
      alreadyCheckedTags.push(name);
      this.setState({
        checkedTags: alreadyCheckedTags
      });
    } else {
      const index = alreadyCheckedTags.indexOf(name);
      alreadyCheckedTags.splice(index, 1);
      this.setState({
        checkedTags: alreadyCheckedTags
      });
    }
  };

  handleText = attribute => {
    let newState = Object.assign({}, this.state);
    return e => {
      newState[attribute] = e.target.value;
      this.setState(newState);
    };
  };

  renderButtonToolbar = () => {
    return (
      <div>
        <ButtonToolbar>
          <Dropdown>
            <Dropdown.Toggle variant="outline-primary mb-2" id="dropdown-basic">
              Select type
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => this.handleSelectType(this.USER)}>
                User
              </Dropdown.Item>
              <Dropdown.Item onClick={() => this.handleSelectType(this.AGENTS)}>
                Agent
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </ButtonToolbar>
      </div>
    );
  };

  renderFormBody = () => {
    return (
      <div>
        <Form.Row>
          <Form.Group as={Col} controlId="formFirstName">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              placeholder="Enter first name"
              onChange={this.handleText("firstName")}
              value={this.state.firstName}
            />
          </Form.Group>

          <Form.Group as={Col} controlId="formLastName">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              placeholder="Enter last name"
              onChange={this.handleText("lastName")}
              value={this.state.lastName}
            />
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} controlId="formEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              onChange={this.handleText("email")}
              value={this.state.email}
            />
          </Form.Group>

          <Form.Group as={Col} controlId="formPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              onChange={this.handleText("password")}
              value={this.state.password}
            />
          </Form.Group>
        </Form.Row>
      </div>
    );
  };

  createUser = () => {
    this.props.create({
      type: this.state.type,
      subtype: this.state.subtype,
      company: {
        id: this.props.company.id,
        name: this.props.company.name
      },
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      tags: this.state.checkedTags,
      teams: this.state.checkedTeams
    });
    this.setState({
      type: this.USER,
      subtype: "",
      addTag: false,
      addTeam: false,
      newTag: "",
      newTeam: "",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      checkedTags: [],
      checkedTeams: []
    });
  };

  getMesaggeNotSelectedTags = () => {
    if (
      this.state.checkedTags.length === 0 &&
      this.state.type === this.AGENTS
    ) {
      return (
        <Alert key={`No-Tag-Alert`} variant="danger">
          The agent you are creating does not have any tags assigned, therefore
          it will not be able to recive chats.
        </Alert>
      );
    } else {
      return null;
    }
  };

  render() {
    return (
      <div>
        {this.renderButtonToolbar()}
        <Jumbotron>
          <h1>{`${this.state.type} ${this.state.subtype}`}</h1>
          {this.setAgentSubtype(this.AGENTS)}
          <Form>
            {this.renderFormBody()}
            <Form.Group as={Col} controlId="formTagTeam">
              {this.renderTagTeams(this.AGENTS, this.state.subtype)}
            </Form.Group>
            <Button variant="success" size="lg" onClick={this.createUser}>
              {" "}
              Create{" "}
            </Button>
          </Form>
          {this.getMesaggeNotSelectedTags()}
        </Jumbotron>
      </div>
    );
  }
}

export default MainDashboardComponent;
