import React, { Component } from "react";
import shortid from "shortid";

import { CellphonesContainer } from "./CompanyDetailsSections";
import { MainDashboardContainer } from "./CompanyDetailsSections";
import { TagTeamsContainer } from "./CompanyDetailsSections";
import { AccountsComponent } from "./CompanyDetailsSections";
// assets

class CompanyDetailsComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      seletctedTab: "linked_cellphones",
      tabsToComponent: {
        linked_cellphones: <CellphonesContainer />,
        company_info: <TagTeamsContainer />,
        create_account: <MainDashboardContainer />,
        linked_accounts: <AccountsComponent />
      }
    };
  }

  fromKeyToUserFriendlyText = key => {
    const splitted_key = key.split("_");
    const user_friendly_text = splitted_key.join(" ");
    return user_friendly_text[0].toUpperCase() + user_friendly_text.slice(1);
  };

  render() {
    let { company } = this.props;
    console.log(Object.keys(this.state.tabsToComponent));
    return (
      <div class="col-7 text-center" id="company-details">
        <h1>{company.name}</h1>
        <div>
          <ul class="nav justify-content-center ">
            {Object.keys(this.state.tabsToComponent).map(key => {
              return (
                <li
                  class={
                    "nav-item " + (key === this.seletctedTab ? "active" : "")
                  }
                  onClick={() => this.setState({ seletctedTab: key })}
                >
                  <a class="nav-link" href="#">
                    {this.fromKeyToUserFriendlyText(key)}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
        <div class="container">
          {this.state.tabsToComponent[this.state.seletctedTab]}
        </div>
      </div>
    );
  }
}

export default CompanyDetailsComponent;
