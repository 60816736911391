// HomeComponent.jsx

import React, { Component } from "react";
import SidebarContainer from "./SidebarContainer";
import CompanyListContainer from "./CompanyListContainer";
import CompanyDetailsContainer from "./CompanyDetailsContainer";
import NewCompanyContainer from "./NewCompanyContainer";

import loading from "../../assets/images/loading.svg";

import "./Home.scss";

class HomeComponent extends Component {
  componentDidMount() {
    const { getCompanies } = this.props;

    getCompanies();
  }

  getComponentByTitle(title) {
    return {
      companies: (
        <div className="col-9" id="companies-container">
          <h2>{title}</h2>
          <div className="row">
            <CompanyListContainer />
            <CompanyDetailsContainer />
          </div>
        </div>
      ),
      "new company": (
        <div className="col-9" id="companies-container">
          <NewCompanyContainer />
        </div>
      ),
    }[title];
  }

  render() {
    const { selectedTitle, onLoading } = this.props;

    return (
      <div className="container-fluid fill">
        <div className="row fill">
          {onLoading && (
            <div class="blanket">
              <img src={loading} alt="Logo" className="center" />
            </div>
          )}
          <SidebarContainer className="m-2" />
          {this.getComponentByTitle(selectedTitle)}
        </div>
      </div>
    );
  }
}

export default HomeComponent;
