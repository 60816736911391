import { useAuth0 } from "@auth0/auth0-react";

const BASE_URL = process.env.REACT_APP_TREBLE_API_URL;
let api = (BASE_URL) => ({
  get: (path) => {
    let url = path;

    if (!url.startsWith("http")) {
      url = `${BASE_URL}${url}`;
    }

    return (callback) => {
      fetch(url)
        .then((response) => response.json())
        .then(callback);
    };
  },

  post: (path, data, headers = {}, includeCredentials = false) => {
    return (callback_ok, callback_error) => {
      let url = path;

      if (!url.startsWith("http")) {
        url = `${BASE_URL}${url}`;
      }

      fetch(url, {
        method: "POST",
        headers: {
          ...headers,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
        credentials: includeCredentials ? "include" : "omit",
      })
        .then((response) => {
          console.log(response);
          if (response.status == 400) {
            response.json().then((data) => {
              callback_error(data.msg);
            });
          } else if (response.status != 200) {
            throw Error(response.statusText);
          } else {
            return response.json();
          }
        })
        .then(callback_ok)
        .catch((error) => {
          console.log(error);
          console.log(typeof error);
          if (typeof callback_error == "function")
            callback_error(error.toString());
        });
    };
  },

  del: (path, data = {}) => {
    return (callback) => {
      fetch(BASE_URL + path, {
        method: "DELETE",
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then(callback);
    };
  },
});

export default api;
