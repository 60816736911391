import React from "react";
import { Badge } from "react-bootstrap";

const AvailableTagsTeams = props => {
  const getCompanyTagsOrTeams = type => {
    if (props.type === "tag") {
      return props.company.tags;
    } else if (props.type === "team") {
      return props.company.teams;
    }
  };
  const companyType = getCompanyTagsOrTeams(props.type);
  return (
    <div>
      {companyType.map(element => (
        <Badge pill variant="info ml-1 mr-1" key={`badge-${element.name}`}>
          {element.name}
        </Badge>
      ))}
    </div>
  );
};

export default AvailableTagsTeams;
