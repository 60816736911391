import React, { Component } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Jumbotron, Badge } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import UserAgentRecord from "./UserAgentRecord";
import AvailableTagsTeams from "./AvailableTagsTeams";
import { ListGroupItem } from "react-bootstrap";
import { ListGroup } from "react-bootstrap";

class TagTeamsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addTag: false,
      addTeam: false,
      newTag: "",
      selectedTeamId: 0,
      newTeam: ""
    };
  }

  getNewTagOrTeamState = type => {
    if (type === "tag") {
      return this.state.newTag;
    } else if (type === "team") {
      return this.state.newTeam;
    }
  };

  addButton = type => {
    const capital_type = type[0].toUpperCase() + type.slice(1);
    return (
      <Form.Row>
        <InputGroup className="mb-3">
          <FormControl
            placeholder={`New ${type} name`}
            aria-describedby="basic-addon2"
            onChange={this.handleText(`new${capital_type}`)}
            value={this.getNewTagOrTeamState(type)}
          />
          <InputGroup.Append>
            <Button
              variant="outline-secondary"
              onClick={() => this.addTagOrTeam(type)}
            >
              Add
            </Button>
          </InputGroup.Append>
        </InputGroup>
      </Form.Row>
    );
  };

  openAddTagOrTeam = type => {
    if (type === "tag" && this.state.addTag === true) {
      return (
        <div>
          <Form.Row>
            <Form.Group controlId="existingTeamForTagCreate">
              <Form.Label>Team to link the new tag</Form.Label>
              <Form.Control
                as="select"
                onChange={e => this.handleSelectTeam(e)}
              >
                {this.props.company.teams.map(team => (
                  <option
                    key={`selectedTeam-${team.id}`}
                    value={team.id}
                  >{`${team.name}`}</option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form.Row>
          {this.addButton(type)}
        </div>
      );
    } else if (type === "team" && this.state.addTeam === true) {
      return this.addButton(type);
    }
  };

  addTagOrTeam = type => {
    if (type === "tag") {
      this.props.addTag({
        companyId: this.props.company.id,
        tagName: this.state.newTag,
        teamId: this.state.selectedTeamId,
        company: {
          id: this.props.company.id,
          name: this.props.company.name
        }
      });
      this.setState({ newTag: "" });
    } else if (type === "team") {
      this.props.addTeam({
        companyId: this.props.company.id,
        teamName: this.state.newTeam,
        company: {
          id: this.props.company.id,
          name: this.props.company.name
        }
      });
      this.setState({ newTeam: "" });
    }
  };

  handleText = attribute => {
    let newState = Object.assign({}, this.state);
    return e => {
      newState[attribute] = e.target.value;
      this.setState(newState);
    };
  };

  handleSelectTeam = e => {
    const teamId = e.target.value;
    this.setState({ selectedTeamId: teamId });

  };

  addTagOrTeamState = type => {
    if (type === "tag") {
      this.setState({ addTag: !this.state.addTag });
      if (this.props.company.teams.length > 0) this.setState({ selectedTeamId: this.props.company.teams[0].id })
      return 
    } else if (type === "team") {
      return this.setState({ addTeam: !this.state.addTeam });
    }
  };

  renderAvailableTagOrTeam = type => {
    const capital_type = type[0].toUpperCase() + type.slice(1);
    return (
      <Form.Row>
        <Form.Group as={Row}>
          <Form.Label>{`Available ${capital_type + "s"}`}</Form.Label>
          <AvailableTagsTeams company={this.props.company} type={type} />
          <div>
            <Button
              variant="btn btn-dark btn-sm"
              key={`new${capital_type}`}
              onClick={() => this.addTagOrTeamState(type)}
            >
              {`New ${type}`}
            </Button>
          </div>
        </Form.Group>
      </Form.Row>
    );
  };

  render() {
    return (
      <div>
        <Jumbotron>
          <Form.Group controlId="formCompanyId" as={Col}>
            <Form.Label>Company ID</Form.Label>
            <Form.Control value={this.props.company.id} readOnly />
          </Form.Group>
          {this.renderAvailableTagOrTeam("tag")}
          {this.openAddTagOrTeam("tag")}
          {this.renderAvailableTagOrTeam("team")}
          {this.openAddTagOrTeam("team")}
        </Jumbotron>
      </div>
    );
  }
}

export default TagTeamsComponent;
