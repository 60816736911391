import React from 'react';
import shortid from 'shortid';

// assets

function CellphonesComponent({
  company,
  cellphones,
  newCellphoneInput,
  errorMsg,
  addDemoCellphone,
  setCellphoneInput,
  deleteCellphone
}) {

  return (
    <div>
      <div class="input-group mb-3">
        <input type="text" 
          class="form-control" 
          placeholder="Cellphone"
          onChange={(e) => setCellphoneInput(e.target.value)} 
        />
        <div class="input-group-append">
          <span class="input-group-text" id="basic-addon2"
            onClick={() => addDemoCellphone(company, newCellphoneInput)}>Add</span>
        </div>
      </div>
      {
        errorMsg &&
        (<div class="alert alert-primary" role="alert">
          { errorMsg }
        </div>)
      } 
      <ul class="list-group">
        {cellphones.map((data, index) => (
        <li class="list-group-item d-flex justify-content-between align-items-center">
            {data.country_code + data.cellphone}
            <span class="badge badge-danger badge-pill"
            onClick={() => deleteCellphone(company, data)}>x</span>
        </li>
        ))}
      </ul>
    </div>
  )
}

export default CellphonesComponent;