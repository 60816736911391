import { api } from "./config";

let REDIRECTION_COMPANY_ID = 527;

const getAll = api.get("/devapi/all/companies");

const getDemoCellphones = (companyId) => {
  const PATH = `/devapi/${companyId}/redirections`;
  return api.get(PATH);
};

const postDemoCellphone = (company_id, cellphone) => {
  const PATH = `/devapi/cellphone_redirection`;
  return api.post(PATH, {
    from_company_id: REDIRECTION_COMPANY_ID,
    to_company_id: company_id,
    cellphone: cellphone,
  });
};

const deleteDemoCellphone = (company_id, country_code, cellphone) => {
  const PATH = `/devapi/cellphone_redirection`;
  return api.del(PATH, {
    from_company_id: REDIRECTION_COMPANY_ID,
    to_company_id: company_id,
    cellphone: cellphone,
    country_code: country_code,
  });
};

const postNewCompany = (name, productLeadOnboarding) => {
  const PATH = `/devapi/company/pilot`;
  return api.post(PATH, {
    name,
    productLeadOnboarding
  });
};

const postNewUser = (company_id, email, first_name, last_name, password) => {
  const PATH = `/devapi/company/${company_id}/user`;
  return api.post(PATH, {
    cellphone: "",
    email,
    first_name,
    last_name,
    password,
  });
};

const getUsers = (company_id) => {
  const PATH = `/devapi/company/${company_id}/users`;
  return api.get(PATH, {
    company_id,
  });
};

const postAdminLogin = (token, userId) => {
  const PATH = `/auth/login/admin`;
  return api.post(
    PATH,
    {
      user_id: userId,
    },
    { Authorization: `Bearer ${token}` }
  );
};

export {
  getAll,
  getDemoCellphones,
  postDemoCellphone,
  deleteDemoCellphone,
  postNewCompany,
  postNewUser,
  getUsers,
  postAdminLogin,
};
