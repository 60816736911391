const SET_ON_LOADING = "SET_ON_LOADING";

const SET_SIDEBAR_SECTION = "SET_SIDEBAR_SECTION";

const FILTER_COMPANIES_BY_TEXT = "FILTER_COMPANIES_BY_TEXT";

const SET_SELECTED_COMPANY = "SET_SELECTED_COMPANY";

const SELECT_DETAILS_TAB = "SELECT_DETAILS_TAB";

const REQUEST_COMPANIES = "REQUEST_COMPANIES";
const RECEIVE_COMPANIES = "RECEIVE_COMPANIES";

const RECEIVE_DEMO_CELLPHONES = "RECEIVE_DEMO_CELLPHONES";
const RECEIVE_TEAMS = "RECEIVE_TEAMS";
const RECEIVE_TAGS = "RECEIVE_TAGS";

const POST_PILOT_CELLPHONE = "POST_PILOT_CELLPHONE";

const POST_PILOT_ENTITY = "POST_PILOT_ENTITY";

const SET_NEW_CELLPHONE_INPUT = "SET_NEW_CELLPHONE_INPUT";
const ON_ADD_DEMO_CELLPHONE_ERROR = "ON_ADD_DEMO_CELLPHONE_ERROR";

const ON_CREATE_COMPANY_ERROR = "ON_CREATE_COMPANY_ERROR";

const RECEIVE_USERS = "RECEIVE_USERS";

const RECEIVE_AGENTS = "RECEIVE_AGENTS";

export default {
  SET_ON_LOADING,
  SET_SIDEBAR_SECTION,
  FILTER_COMPANIES_BY_TEXT,
  SET_SELECTED_COMPANY,
  SELECT_DETAILS_TAB,
  REQUEST_COMPANIES,
  RECEIVE_COMPANIES,
  RECEIVE_DEMO_CELLPHONES,
  RECEIVE_TEAMS,
  RECEIVE_TAGS,
  POST_PILOT_CELLPHONE,
  POST_PILOT_ENTITY,
  SET_NEW_CELLPHONE_INPUT,
  ON_ADD_DEMO_CELLPHONE_ERROR,
  ON_CREATE_COMPANY_ERROR,
  RECEIVE_AGENTS,
  RECEIVE_USERS
};
