import { connect } from 'react-redux';
import CellphonesComponent from './CellphonesComponent';

// This will make sense to you once we discuss the Redux code,
// but for now, just know that 'homeOperations' will let you trigger 
// Redux actions.
import { operations } from '../duck';

const mapStateToProps = (state) => {
  const { 
    demoCellphones, 
    selectedCompany,  
    newCellphoneInput,
    onAddNewCellphoneErrorMsg
  } = state.home;

  return {
    company: selectedCompany,
    cellphones: demoCellphones,
    newCellphoneInput,
    errorMsg: onAddNewCellphoneErrorMsg,
  }
};

const mapDispatchToProps = (dispatch) => {

  const addDemoCellphone = (company, cellphone) => {
      dispatch(operations.addDemoCellphone(company, cellphone))
  };

  const setCellphoneInput = (text) => {
      dispatch(operations.setNewCellphoneInput(text))
  }

  const deleteCellphone = (company, cellphone) => {
    dispatch(operations.removeDemoCellphone(company, cellphone.country_code, cellphone.cellphone))
  }

  
  return { 
    addDemoCellphone,
    setCellphoneInput,
    deleteCellphone          
  };

};

const CellphonesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CellphonesComponent);

export default CellphonesContainer;