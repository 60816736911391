import {
  getAll,
  getDemoCellphones,
  postDemoCellphone,
  deleteDemoCellphone,
  postNewCompany,
  postNewUser,
  getUsers,
  postAdminLogin,
} from "../../../api/treble/company";

import {
  postNewCompany as postNewAgentCompany,
  postNewAgent,
  getAgents,
  getTeams,
  getTags,
  postTag,
  postTeam,
  postTagToAgent,
  postTeamToAgent,
  deleteTagForAgent,
  deleteTeamForAgent,
} from "../../../api/agent/company";

import {
  setSidebarSection,
  filterCompaniesByText,
  setSelectedCompany,
  requestCompanies,
  receiveCompanies,
  receiveDemoCellphones,
  receiveTeams,
  receiveTags,
  selectDetailsTab,
  setNewCellphoneInput,
  onAddDemoCellphoneError,
  setOnLoading,
  onCreateCompanyError,
  receiveUsers,
  receiveAgents,
} from "./actions";

const getCompanies = () => {
  return (dispatch) => {
    dispatch(setOnLoading(true));
    dispatch(requestCompanies());

    return getAll((data) => {
      dispatch(setOnLoading(false));
      dispatch(receiveCompanies(data.companies));
      dispatch(filterCompaniesByText(""));
    });
  };
};

const selectCompany = (company) => {
  return (dispatch) => {
    company.tags = [];
    company.teams = [];
    company.agents = [];
    company.users = [];
    dispatch(setOnLoading(true));
    dispatch(setSelectedCompany(company));

    getDemoCellphones(company.id)((data) => {
      dispatch(setOnLoading(false));
      dispatch(receiveDemoCellphones(data.redirections));
    });

    getUsers(company.id)((data) => {
      dispatch(setOnLoading(false));
      dispatch(receiveUsers(data.users));
    });

    getAgents(company.id)((data) => {
      dispatch(setOnLoading(false));
      dispatch(receiveAgents(data));
    });

    getTeams(company.id)((data) => {
      dispatch(setOnLoading(false));
      dispatch(receiveTeams(data));
    });

    getTags(company.id)((data) => {
      dispatch(setOnLoading(false));
      dispatch(receiveTags(data));
    });
  };
};

const addDemoCellphone = (company, cellphone) => {
  return (dispatch) => {
    dispatch(setOnLoading(true));

    return postDemoCellphone(company.id, cellphone)(
      (data) => {
        dispatch(setOnLoading(false));
        dispatch(selectCompany(company));
      },
      (error) => {
        dispatch(setOnLoading(false));
        dispatch(onAddDemoCellphoneError(error));
      }
    );
  };
};

const removeDemoCellphone = (company, country_code, cellphone) => {
  return (dispatch) => {
    dispatch(setOnLoading(true));
    return deleteDemoCellphone(
      company.id,
      country_code,
      cellphone
    )(
      (data) => {
        dispatch(setOnLoading(false));
        dispatch(selectCompany(company));
      },
      (error) => {
        dispatch(setOnLoading(false));
        dispatch(onAddDemoCellphoneError(error));
      }
    );
  };
};

const createNewCompany = (name, productLeadOnboarding) => {
  return (dispatch) => {
    dispatch(setOnLoading(true));
    return postNewCompany(name, productLeadOnboarding)(
      (data) => {
        postNewAgentCompany(
          data.id,
          name,
          data.api_key
        )(
          (data) => {
            dispatch(setOnLoading(false));
          },
          (error) => {
            dispatch(onCreateCompanyError(error));
          }
        );
      },
      (error) => {
        console.log(error);
        dispatch(setOnLoading(false));
        dispatch(onCreateCompanyError(error));
      }
    );
  };
};

const addNewUser = (company, email, first_name, last_name, password) => {
  return (dispatch) => {
    dispatch(setOnLoading(true));

    return postNewUser(
      company.id,
      email,
      first_name,
      last_name,
      password
    )(
      (data) => {
        dispatch(setOnLoading(false));
        //TODO dispatch(selectCompany(company));
        // dispatch(getUsers(company))
        alert("Created user sucessfully!");
        dispatch(selectCompany(company));
      },
      (error) => {
        dispatch(setOnLoading(false));
        //TODO dispatch(onAddUserError(error));
        alert("Failed creating user!");
        dispatch(selectCompany(company));
      }
    );
  };
};

const addNewAgent = (
  agent_type,
  company,
  email,
  first_name,
  last_name,
  password,
  tags,
  teams
) => {
  return (dispatch) => {
    dispatch(setOnLoading(true));

    return postNewAgent(
      agent_type,
      company.id,
      email,
      first_name,
      last_name,
      password,
      tags,
      teams
    )(
      (data) => {
        dispatch(setOnLoading(false));
        //TODO dispatch(selectCompany(company));
        // dispatch(getAgents(company))
        alert("Created agent sucessfully!");
        dispatch(selectCompany(company));
      },
      (error) => {
        dispatch(setOnLoading(false));
        //TODO dispatch(onAddUserError(error));
        alert("Failed creating agent!");
        dispatch(selectCompany(company));
      }
    );
  };
};

const addTag = (companyId, tag, team, company) => {
  return (dispatch) => {
    dispatch(setOnLoading(true));
    return postTag(
      companyId,
      tag,
      team
    )(
      (data) => {
        dispatch(setOnLoading(false));
        alert("Created tag sucessfully!");
        dispatch(selectCompany(company));
      },
      (error) => {
        dispatch(setOnLoading(false));
        alert("Failed creating tag!");
        dispatch(selectCompany(company));
      }
    );
  };
};

const addTeam = (companyId, team, company) => {
  return (dispatch) => {
    dispatch(setOnLoading(true));
    return postTeam(company.id, team)(
      (data) => {
        dispatch(setOnLoading(false));
        alert("Created team sucessfully!");
        dispatch(selectCompany(company));
      },
      (error) => {
        dispatch(setOnLoading(false));
        alert("Failed creating team!");
        dispatch(selectCompany(company));
      }
    );
  };
};

const addTagsToAgent = (company, agent, tagIds) => {
  return (dispatch) => {
    dispatch(setOnLoading(true));
    return postTagToAgent(agent.id, tagIds)(
      (data) => {
        dispatch(setOnLoading(false));
        dispatch(selectCompany(company));
      },
      (error) => {
        dispatch(setOnLoading(false));
        dispatch(selectCompany(company));
      }
    );
  };
};

const addTeamsToAgent = (company, agent, teamIds) => {
  return (dispatch) => {
    dispatch(setOnLoading(true));
    return postTeamToAgent(agent.id, teamIds)(
      (data) => {
        dispatch(setOnLoading(false));
        dispatch(selectCompany(company));
      },
      (error) => {
        dispatch(setOnLoading(false));
        dispatch(selectCompany(company));
      }
    );
  };
};

const deleteTagAgent = (company, agent, tagId) => {
  return (dispatch) => {
    dispatch(setOnLoading(true));
    return deleteTagForAgent(agent.id, tagId)(
      (data) => {
        dispatch(setOnLoading(false));
        dispatch(selectCompany(company));
      },
      (error) => {
        dispatch(setOnLoading(false));
        dispatch(selectCompany(company));
      }
    );
  };
};

const deleteTeamAgent = (company, agent, teamId) => {
  return (dispatch) => {
    dispatch(setOnLoading(true));
    return deleteTeamForAgent(agent.id, teamId)(
      (data) => {
        dispatch(setOnLoading(false));
        dispatch(selectCompany(company));
      },
      (error) => {
        dispatch(setOnLoading(false));
        dispatch(selectCompany(company));
      }
    );
  };
};

const adminLogin = (token, userId) => {
  return (dispatch) => {
    dispatch(setOnLoading(true));
    return postAdminLogin(token, userId)(
      (data) => {
        dispatch(setOnLoading(false));
        console.log("data", data);
        const token = data.access_token;
        window.open(`${process.env.REACT_APP_TREBLE_APP_URL}/admin-auth?token=${token}`, "_blank");
      },
      (error) => {
        dispatch(setOnLoading(false));
        console.log("error", error);
      }
    );
  };
};

export default {
  setSidebarSection,
  filterCompaniesByText,
  selectCompany,
  getCompanies,
  addDemoCellphone,
  selectDetailsTab,
  setNewCellphoneInput,
  removeDemoCellphone,
  createNewCompany,
  addNewUser,
  addNewAgent,
  addTag,
  addTeam,
  addTagsToAgent,
  addTeamsToAgent,
  deleteTagAgent,
  deleteTeamAgent,
  adminLogin,
};
