import { connect } from "react-redux";
import MainDashboardComponent from "./MainDashboardComponent";

// This will make sense to you once we discuss the Redux code,
// but for now, just know that 'homeOperations' will let you trigger
// Redux actions.

import { operations } from "../duck";

const mapStateToProps = state => {
  const { selectedCompany } = state.home;
  return {
    company: selectedCompany
  };
};

const mapDispatchToProps = dispatch => {
  const create = entity => {
    console.log("adding entinty");
    console.log(entity);
    if (entity.type === "USER") {
      dispatch(
        operations.addNewUser(
          entity.company,
          entity.email,
          entity.firstName,
          entity.lastName,
          entity.password
        )
      );
    } else if (entity.type === "AGENTS") {
      dispatch(
        operations.addNewAgent(
          entity.subtype,
          entity.company,
          entity.email,
          entity.firstName,
          entity.lastName,
          entity.password,
          entity.tags,
          entity.teams
        )
      );
    }
  };

  return {
    create
  };
};

const MainDashboardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MainDashboardComponent);

export default MainDashboardContainer;
