import { connect } from 'react-redux';
import HomeComponent from './HomeComponent';
import { operations } from './duck';

const mapStateToProps = (state) => {
  const { selectedSidebarSection, onLoading } = state.home;
  const selectedTitle = selectedSidebarSection;
  return { selectedTitle, onLoading }
};

const mapDispatchToProps = dispatch => {

  const getCompanies = () => dispatch(operations.getCompanies());
  return {
    getCompanies
  }
};

const HomeContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeComponent);

export default HomeContainer;