import React from 'react';
import shortid from 'shortid';

// assets
import './Sidebar.scss'
import logo from '../../assets/images/logo-treble.png'

function SidebarComponent({
  sidebarTitles,
  sidebarOnItemClick,
  selectedSidebarSection,
}) {

  return (
    <div className='col-3 text-center fill' id='side-bar'>
      <img src={logo} alt="Logo" className='center'/>
      <ul className='list-unstyled'>
        {sidebarTitles.map((data, index) => (
          <li 
            key={shortid.generate()} 
            className={data == selectedSidebarSection ?'selected':''}
            onClick={() => sidebarOnItemClick(index)}>
            {data}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default SidebarComponent;