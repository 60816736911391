import React, { Component } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import UserDashboardContainer from "./UserDashboardContainer";
import AgentDashboardContainer from "./AgentDashboardContainer";

const USER = "USER";
const AGENTS = "AGENTS";

class AccountsComponent extends Component {
	constructor() {
		super();
		this.state = {
			type: USER
		};
	}
	render() {
		return (
			<>
				<ButtonToolbar>
					<Dropdown>
						<Dropdown.Toggle variant="outline-primary mb-2" id="dropdown-basic">
							Select type
						</Dropdown.Toggle>

						<Dropdown.Menu>
							<Dropdown.Item onClick={() => this.setState({ type: AGENTS })}>
								Agent
							</Dropdown.Item>
							<Dropdown.Item onClick={() => this.setState({ type: USER })}>
								User
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</ButtonToolbar>
				{this.state.type === USER && (
					<UserDashboardContainer/>
				)}
				{this.state.type === AGENTS && (
					<AgentDashboardContainer/>
				)}
			</>
		);
	}
}

export default AccountsComponent;