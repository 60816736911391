import { connect } from 'react-redux';
import SidebarComponent from './CompanyListComponent';

// This will make sense to you once we discuss the Redux code,
// but for now, just know that 'homeOperations' will let you trigger 
// Redux actions.
import { operations } from './duck';

const mapStateToProps = (state) => {
  const { filteredCompanies } = state.home;
  return {
    filteredCompanies
  }
};

const mapDispatchToProps = (dispatch) => {

  const onSearchTextChange = (text) => {
      dispatch(operations.filterCompaniesByText(text))
  };

  const onCompanyClick = (company) => {
      dispatch(operations.selectCompany(company))
  };
  
  return { onSearchTextChange, onCompanyClick };
};

const SidebarContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SidebarComponent);

export default SidebarContainer;