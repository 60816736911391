import { connect } from "react-redux";
import NewCompanyComponent from "./NewCompanyComponent";

// This will make sense to you once we discuss the Redux code,
import { operations } from "./duck";

const mapStateToProps = (state) => {
  const { createCompanyError } = state.home;

  return { error: createCompanyError };
};

const mapDispatchToProps = (dispatch) => {
  const createNewCompany = (name, productLeadOnboarding) => {
    dispatch(operations.createNewCompany(name, productLeadOnboarding));
  };

  return { createNewCompany };
};

const NewCompanyContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(NewCompanyComponent);

export default NewCompanyContainer;
