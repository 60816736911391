import React, { Component } from "react";
import { OverlayTrigger, Popover, Button, Form } from "react-bootstrap";
import { Badge } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import "./AgentDashboardComponent.scss";

// assets
class AgentDashboardComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editable: false
    };
    this.showAddTag = false;
    this.state = {
      checkedTags: [],
      checkedTeams: []
    };
  }

  getTagsOrTeams = param => {
    return function nameListOfTagsOrTeams(Agent) {
      let nameList = [];
      for (let i = 0; i < Agent[param].length; i++) {
        nameList[i] = Agent[param][i].name;
      }
      return nameList;
    };
  };

  getCompanyTagsOrTeams = type => {
    if (type === "tag") {
      return this.props.company.tags;
    } else if (type === "team") {
      return this.props.company.teams;
    }
  };

  getDeleteTagOrTeamToAgent = type => {
    if (type === "tag") {
      return this.props.deleteTagAgent;
    } else if (type === "team") {
      return this.props.deleteTeamAgent;
    }
  };

  formBadge = (agent, type, label) => {
    const companyType = this.getCompanyTagsOrTeams(type);
    let elem = "";
    for (let i = 0; i < companyType.length; i++) {
      const tmpElem = companyType[i];
      if (tmpElem.name === label) {
        elem = tmpElem;
        break;
      }
    }

    return (
      <Badge pill variant="info ml-1 mr-1" key={`${agent.id}_${label}`}>
        <Button
          variant="danger"
          className="rounded-btn"
          size="sm"
          onClick={() => {
            this.getDeleteTagOrTeamToAgent(type)(
              this.props.company,
              agent,
              elem.id
            );
            this.toggleEditSwitch();
          }}
        >
          x
        </Button>
        {label}
      </Badge>
    );
  };

  toggleEdit = () => {
    const edit = this.state.editable;
    this.setState({ editable: !edit });
    const btns = Array.from(document.getElementsByClassName("rounded-btn"));
    if (!edit) {
      btns.forEach(btn => (btn.style.display = "inline"));
    } else {
      btns.forEach(btn => (btn.style.display = "none"));
    }
  };

  toggleEditSwitch = () => {
    document.getElementsByClassName("edit-switch")[0].click();
  };

  handleCheckBoxChangeType = type => {
    if (type === "tag") {
      return this.handleCheckboxChangeTags;
    } else if (type === "team") {
      return this.handleCheckboxChangeTeams;
    }
  };

  handleCheckboxChangeTags = event => {
    const target = event.target;
    const checked = target.checked;
    const name = parseInt(target.name);
    const alreadyCheckedTags = this.state.checkedTags;
    if (checked) {
      const tmpTag = { id: name };
      alreadyCheckedTags.push(tmpTag);
    } else {
      let removeIndex = null;
      for (let i = 0; i < alreadyCheckedTags.length; i++) {
        if (alreadyCheckedTags[i].id === name) {
          removeIndex = i;
          break;
        }
      }
      alreadyCheckedTags.splice(removeIndex, 1);
    }
    this.setState({
      checkedTags: alreadyCheckedTags
    });
  };

  handleCheckboxChangeTeams = event => {
    const target = event.target;
    const checked = target.checked;
    const name = parseInt(target.name);
    const alreadyCheckedTeams = this.state.checkedTeams;
    if (checked) {
      const tmpTeam = { id: name };
      alreadyCheckedTeams.push(tmpTeam);
    } else {
      let removeIndex = null;
      for (let i = 0; i < alreadyCheckedTeams.length; i++) {
        if (alreadyCheckedTeams[i].id === name) {
          removeIndex = i;
          break;
        }
      }
      alreadyCheckedTeams.splice(removeIndex, 1);
    }
    this.setState({
      checkedTeams: alreadyCheckedTeams
    });
  };

  filterAgentsMissingTagsOrTeams = (agent, type) => {
    if (type === "tag") {
      let filter = this.props.company.tags.filter(
        companyTag =>
          !this.getTagsOrTeams("tags")(agent).includes(companyTag.name)
      );
      return filter;
    } else if (type === "team") {
      let filter = this.props.company.teams.filter(
        companyTeam =>
          !this.getTagsOrTeams("teams")(agent).includes(companyTeam.name)
      );
      return filter;
    }
  };

  addTagOrTeam = type => {
    if (type === "tag") {
      return this.props.addTagsToAgent;
    } else if (type === "team") {
      return this.props.addTeamsToAgent;
    }
  };

  getCheckedTagsOrTeams = type => {
    if (type === "tag") {
      return this.state.checkedTags;
    } else if (type === "team") {
      return this.state.checkedTeams;
    }
  };

  emptyCheckedTagsOrTeams = type => {
    if (type === "tag") {
      this.setState({ checkedTags: [] });
    } else if (type === "team") {
      this.setState({ checkedTeams: [] });
    }
  };

  addPlusButton = (agent, type) => {
    const capital_type = type[0].toUpperCase() + type.slice(1);
    const missing = this.filterAgentsMissingTagsOrTeams(agent, type);
    const popover = (
      <Popover id="popover-basic">
        <Popover.Title as="h3">{`${capital_type + "s"}`}</Popover.Title>
        <Popover.Content>
          {
            <ul className="tag-teams">
              {missing.map(elem => (
                <li key={`${type}-${elem.name}`} className="mb-1">
                  <Form.Check
                    name={elem.id}
                    id={`${type}-${elem.name}`}
                    label={elem.name}
                    onChange={e => {
                      this.handleCheckBoxChangeType(type)(e);
                    }}
                  />
                </li>
              ))}
            </ul>
          }
          <Button
            variant="success"
            block
            onClick={() => {
              this.addTagOrTeam(type)(
                this.props.company,
                agent,
                this.getCheckedTagsOrTeams(type)
              );
              this.emptyCheckedTagsOrTeams(type);
              this.toggleEditSwitch();
              document.getElementsByClassName("header")[0].click();
            }}
          >
            Send
          </Button>
        </Popover.Content>
      </Popover>
    );
    return (
      <OverlayTrigger
        trigger="click"
        rootClose
        placement="left"
        overlay={popover}
      >
        <Button
          variant="success"
          className="rounded-btn"
          size="sm"
          key={`${agent.id}_add${type}`}
        >
          +
        </Button>
      </OverlayTrigger>
    );
  };

  render() {
    return (
      <div>
        <div>
          <h4 className="header">This company has the following agents</h4>
        </div>
        <div class="custom-control custom-switch float-left">
          <input
            type="checkbox"
            className="custom-control-input edit-switch"
            id="editSwitch"
            onChange={this.toggleEdit}
          />
          <label class="custom-control-label" htmlFor="editSwitch">
            Edit team/tags
          </label>
        </div>
        <div class="Agent">
          <div class="agent" align="left">
            <Table responsive bordered striped>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Type</th>
                  <th>Tags</th>
                  <th>Teams</th>
                </tr>
              </thead>
              <tbody>
                {this.props.company.agents.map(agent => (
                  <tr key={`Agent_${agent.id}`}>
                    <td>{agent.first_name + " " + agent.last_name}</td>
                    <td>{agent.email}</td>
                    <td>{agent.type}</td>
                    <td>
                      {this.addPlusButton(agent, "tag")}
                      {this.getTagsOrTeams("tags")(agent).map(tag =>
                        this.formBadge(agent, "tag", tag)
                      )}
                    </td>
                    <td>
                      {this.addPlusButton(agent, "team")}
                      {this.getTagsOrTeams("teams")(agent).map(team =>
                        this.formBadge(agent, "team", team)
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    );
  }
}

export default AgentDashboardComponent;
