import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

// redux-logger is a middleware that lets you log every state change
import logger from "redux-logger";

// redux-thunk is a middleware that lets you dispatch async actions
import thunk from "redux-thunk";
import { createStore, applyMiddleware } from "redux";
import App from "./app/App";
import rootReducer from "./reducers";
import config from "./auth_config";
import { Auth0Provider } from "@auth0/auth0-react";

import "./index.scss";
//import registerServiceWorker from './registerServiceWorker';

const middleware = applyMiddleware(thunk, logger);
const store = createStore(rootReducer, middleware);

function unregister() {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      for (let registration of registrations) {
        registration.unregister();
        console.log("registration", registration);
      }
    });
  }
}
unregister();

ReactDOM.render(
  <Auth0Provider
    audience={config.audience}
    domain={config.domain}
    clientId={config.clientId}
    scope={config.scope}
    redirectUri={window.location.origin}
    onRedirectCallback={() => {}}
  >
    <Provider store={store}>
      <App />
    </Provider>
  </Auth0Provider>,
  document.getElementById("root")
);
//registerServiceWorker();
