import { connect } from "react-redux";
import AgentDashboardComponent from "./AgentDashboardComponent";

// This will make sense to you once we discuss the Redux code,
// but for now, just know that 'homeOperations' will let you trigger
// Redux actions.

import { operations } from "../duck";

const mapStateToProps = state => {
  const { selectedCompany } = state.home;

  return {
    company: selectedCompany
  };
};

const mapDispatchToProps = dispatch => {
  const addTagsToAgent = (company, agent, tags) => {
    console.log("adding tags to agent");
    console.log(tags);
    dispatch(operations.addTagsToAgent(company, agent, tags));
  };

  const addTeamsToAgent = (company, agent, teams) => {
    console.log("adding teams to agent");
    console.log(teams);
    dispatch(operations.addTeamsToAgent(company, agent, teams));
  };

  const deleteTagAgent = (company, agent, tag) => {
    console.log("deleting tag for agent");
    console.log(tag);
    dispatch(operations.deleteTagAgent(company, agent, tag));
  };

  const deleteTeamAgent = (company, agent, team) => {
    console.log("deleting team for agent");
    console.log(team);
    dispatch(operations.deleteTeamAgent(company, agent, team));
  };

  return {
    addTagsToAgent,
    addTeamsToAgent,
    deleteTagAgent,
    deleteTeamAgent
  };
};

const AgentDashboardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AgentDashboardComponent);

export default AgentDashboardContainer;
