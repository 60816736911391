import types from "./types.js";

const setSidebarSection = value => ({
  type: types.SET_SIDEBAR_SECTION,
  value: value
});

const filterCompaniesByText = value => ({
  type: types.FILTER_COMPANIES_BY_TEXT,
  value: value
});

const setSelectedCompany = value => ({
  type: types.SET_SELECTED_COMPANY,
  value: value
});

const requestCompanies = () => ({
  type: types.REQUEST_COMPANIES
});

const receiveCompanies = value => ({
  type: types.RECEIVE_COMPANIES,
  value: value
});

const receiveDemoCellphones = value => ({
  type: types.RECEIVE_DEMO_CELLPHONES,
  value: value
});

const receiveTeams = value => ({
  type: types.RECEIVE_TEAMS,
  value: value
});

const receiveTags = value => ({
  type: types.RECEIVE_TAGS,
  value: value
});

const postPilotCellphone = value => ({
  type: types.POST_PILOT_CELLPHONE,
  value: value
});

const selectDetailsTab = value => ({
  type: types.SELECT_DETAILS_TAB,
  value: value
});

const setNewCellphoneInput = value => ({
  type: types.SET_NEW_CELLPHONE_INPUT,
  value: value
});

const onAddDemoCellphoneError = value => ({
  type: types.ON_ADD_DEMO_CELLPHONE_ERROR,
  value: value
});

const setOnLoading = value => ({
  type: types.SET_ON_LOADING,
  value: value
});
const receiveUsers = value => ({
  type: types.RECEIVE_USERS,
  value: value
});
const receiveAgents = value => ({
  type: types.RECEIVE_AGENTS,
  value: value
});

const onCreateCompanyError = value => ({
  type: types.ON_CREATE_COMPANY_ERROR,
  value: value
});

export {
  setSidebarSection,
  filterCompaniesByText,
  setSelectedCompany,
  requestCompanies,
  receiveCompanies,
  receiveDemoCellphones,
  receiveTeams,
  receiveTags,
  postPilotCellphone,
  selectDetailsTab,
  setNewCellphoneInput,
  onAddDemoCellphoneError,
  setOnLoading,
  onCreateCompanyError,
  receiveAgents,
  receiveUsers
};
