import { api } from "./config";

const postNewCompany = (companyId, companyName, companyApiKey) => {
  const PATH = `/admin/company/pilot`;
  return api.post(PATH, {
    id: companyId,
    name: companyName,
    api_key: companyApiKey,
  });
};

const postNewAgent = (
  agent_type,
  company_id,
  email,
  first_name,
  last_name,
  password,
  tags,
  teams
) => {
  const PATH = `/admin/create`;
  const body = {
    agent_type,
    company_id,
    email,
    first_name,
    last_name,
    password,
    tags: tags.map((tag) => parseInt(tag)),
    teams,
  };
  return api.post(PATH, body);
};
const getAgents = (company_id) => {
  const PATH = `/admin/company/${company_id}/agents`;
  return api.get(PATH, {
    company_id,
  });
};

const getTeams = (company_id) => {
  const PATH = `/admin/company/${company_id}/teams`;
  return api.get(PATH);
};

const getTags = (company_id) => {
  const PATH = `/admin/company/${company_id}/tags`;
  return api.get(PATH);
};

const postTag = (company_id, name, team_id) => {
  const PATH = `/admin/company/${company_id}/tag`;
  return api.post(PATH, {
    name,
    team_id,
  });
};

const postTeam = (company_id, name) => {
  const PATH = `/admin/company/${company_id}/team`;
  return api.post(PATH, {
    name,
  });
};

const postTagToAgent = (agent_id, tags) => {
  const PATH = `/superuser/agent/${agent_id}/tags`;
  return api.post(PATH, {
    tags,
  });
};

const postTeamToAgent = (agent_id, teams) => {
  const PATH = `/superuser/agent/${agent_id}/teams`;
  return api.post(PATH, {
    teams,
  });
};

const deleteTagForAgent = (agent_id, tag_id) => {
  const PATH = `/superuser/agent/${agent_id}/tag/${tag_id}`;
  return api.del(PATH);
};

const deleteTeamForAgent = (agent_id, team_id) => {
  const PATH = `/superuser/agent/${agent_id}/team/${team_id}`;
  return api.del(PATH);
};

export {
  postNewCompany,
  postNewAgent,
  getTeams,
  getTags,
  getAgents,
  postTeam,
  postTag,
  postTagToAgent,
  postTeamToAgent,
  deleteTagForAgent,
  deleteTeamForAgent,
};
