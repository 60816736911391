import types from "./types";

const INITIAL_STATE = {
  onLoading: false,
  sidebarTitles: ["companies", "new company"],
  selectedSidebarSection: "companies",
  selectedDetailsTab: "cellphones",
  loadingCompanies: false,
  newCellphoneInput: "",
  onAddNewCellphoneErrorMsg: "",
  companies: [],
  filteredCompanies: [],
  selectedCompany: {
    id: 0,
    name: "",
    tags: [],
    teams: [],
    agents: [],
    users: []
  },
  demoCellphones: [],
  createCompanyError: ""
};

const homeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_ON_LOADING: {
      const { value } = action;
      return {
        ...state,
        onLoading: value
      };
    }

    case types.SET_SIDEBAR_SECTION: {
      const { value } = action;
      const { sidebarTitles } = state;
      return {
        ...state,
        selectedSidebarSection: sidebarTitles[value]
      };
    }

    case types.FILTER_COMPANIES_BY_TEXT: {
      const { value } = action;
      const { companies } = state;
      return {
        ...state,
        filteredCompanies: companies.filter(company =>
          company.name.toLowerCase().includes(value.toLowerCase())
        )
      };
    }

    case types.SET_SELECTED_COMPANY: {
      const { value } = action;
      return {
        ...state,
        selectedCompany: value
      };
    }

    case types.RECEIVE_TEAMS: {
      const { value } = action;
      return {
        ...state,
        selectedCompany: {
          ...state.selectedCompany,
          teams: value
        }
      };
    }

    case types.RECEIVE_TAGS: {
      const { value } = action;
      return {
        ...state,
        selectedCompany: {
          ...state.selectedCompany,
          tags: value
        }
      };
    }
    case types.RECEIVE_AGENTS: {
      const { value } = action;
      return {
        ...state,
        selectedCompany: {
          ...state.selectedCompany,
          agents: value
        }
      };
    }
    case types.RECEIVE_USERS: {
      const { value } = action;
      return {
        ...state,
        selectedCompany: {
          ...state.selectedCompany,
          users: value
        }
      };
    }
    case types.SELECT_DETAILS_TAB: {
      const { value } = action;
      return {
        ...state,
        selectedDetailsTab: value
      };
    }

    case types.REQUEST_COMPANIES: {
      return {
        ...state,
        loadingCompanies: true
      };
    }

    case types.RECEIVE_COMPANIES: {
      const { value } = action;
      return {
        ...state,
        companies: value,
        loadingCompanies: false
      };
    }

    case types.RECEIVE_DEMO_CELLPHONES: {
      const { value } = action;
      return {
        ...state,
        demoCellphones: value
      };
    }

    case types.SET_NEW_CELLPHONE_INPUT: {
      const { value } = action;
      return {
        ...state,
        newCellphoneInput: value
      };
    }

    case types.ON_ADD_DEMO_CELLPHONE_ERROR: {
      const { value } = action;
      return {
        ...state,
        onAddNewCellphoneErrorMsg: value
      };
    }

    case types.ON_CREATE_COMPANY_ERROR: {
      const { value } = action;
      return {
        ...state,
        createCompanyError: value
      };
    }

    default:
      return state;
  }
};

export default homeReducer;
