import React from 'react';



// assets
import './CompanyList.scss'
//import logo from '../../assets/images/logo-treble.png'

function CompanyListComponent({
  filteredCompanies,
  onSearchTextChange,
  onCompanyClick,
}) {

  return (
    <div className='col-5' id='company-list'>
      <input className='searchbox form-control' onChange={(e) => onSearchTextChange(e.target.value)}/>
      <ul className='list-unstyled'>
        {filteredCompanies.map((data, index) => (
          <li 
            key={index}
            onClick={() => onCompanyClick(data)}>
            {data.name}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default CompanyListComponent;