import { connect } from 'react-redux';
import SidebarComponent from './SidebarComponent';

// This will make sense to you once we discuss the Redux code,
// but for now, just know that 'homeOperations' will let you trigger 
// Redux actions.
import { operations } from './duck';

const mapStateToProps = (state) => {
  const { sidebarTitles, selectedSidebarSection } = state.home;
  return {
    sidebarTitles,
    selectedSidebarSection
  }
};

const mapDispatchToProps = (dispatch) => {
  const sidebarOnItemClick = (index) => {
      dispatch(operations.setSidebarSection(index))
  };
  
  return { sidebarOnItemClick };
};

const SidebarContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SidebarComponent);

export default SidebarContainer;