import { connect } from 'react-redux';
import CompanyDetailsComponent from './CompanyDetailsComponent';

// This will make sense to you once we discuss the Redux code,
// but for now, just know that 'homeOperations' will let you trigger 
// Redux actions.
import { operations } from './duck';

const mapStateToProps = (state) => {
  const { selectedCompany, demoCellphones } = state.home;
  return {
    company: selectedCompany,
    demoCellphones
  }
};

const mapDispatchToProps = (dispatch) => {
  const sidebarOnItemClick = (index) => {
      dispatch(operations.setSidebarSection(index))
  };
  
  return { };
};

const CompanyDetailsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyDetailsComponent);

export default CompanyDetailsContainer;