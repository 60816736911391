// HomeComponent.jsx

import React, { Component } from "react";

class NewCompanyComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      plo: false,
    };
  }

  onSubmit = () => {
    const { createNewCompany } = this.props;
    createNewCompany(this.state.name, this.state.plo);
    this.setState({
      name: "",
      plo: false,
    });
  };

  render() {
    const errorMsg = this.props.error;
    return (
      <form>
        <h1>Crear piloto</h1>
        <div class="form-group">
          <label for="new-pilot-company-name">Company Name</label>
          <input
            type="text"
            className="form-control"
            id="new-pilot-company-name"
            placeholder="company name"
            value={this.state.name}
            onChange={(e) => {
              this.setState({ name: e.target.value });
            }}
          />
          <div className="plo">
            <label for="new-pilot-company-plo">Product lead onboarding</label>
            <input
              type="checkbox"
              id="new-pilot-company-plo"
              checked={this.state.plo}
              onClick={() => {
                this.setState({ plo: !this.state.plo });
              }}
            ></input>
          </div>
        </div>
        <div class="btn btn-success" onClick={() => this.onSubmit()}>
          Crear
        </div>
        {errorMsg && (
          <div class="alert alert-primary" role="alert">
            {errorMsg}
          </div>
        )}
      </form>
    );
  }
}

export default NewCompanyComponent;
