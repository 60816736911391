import { connect } from "react-redux";
import TagTeamsComponent from "./TagTeamsComponent";

// This will make sense to you once we discuss the Redux code,
// but for now, just know that 'homeOperations' will let you trigger
// Redux actions.
import { operations } from "../duck";

const mapStateToProps = state => {
  const { selectedCompany } = state.home;
  return {
    company: selectedCompany
  };
};

const mapDispatchToProps = dispatch => {
  const addTag = company => {
    dispatch(
      operations.addTag(
        company.companyId,
        company.tagName,
        company.teamId,
        company.company
      )
    );
  };

  const addTeam = company => {
    dispatch(
      operations.addTeam(company.companyId, company.teamName, company.company)
    );
  };

  return {
    addTag,
    addTeam
  };
};

const TagTeamsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TagTeamsComponent);

export default TagTeamsContainer;
