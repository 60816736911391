import { connect } from "react-redux";
import { operations } from "../duck";
import UserDashboardComponent from "./UserDashboardComponent";

const mapStateToProps = (state) => {
  const { selectedCompany } = state.home;
  return { company: selectedCompany };
};

const mapDispatchToProps = (dispatch) => {
  const adminLogin = (token, userId) => {
    dispatch(operations.adminLogin(token, userId));
  };

  return {
    adminLogin,
  };
};

const AgentDashboardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserDashboardComponent);

export default AgentDashboardContainer;
